var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cont-whole-box"},[_c('div',{staticClass:"leftrig-box"},[_c('div',{staticClass:"leftrig-left-box",staticStyle:{"width":"-webkit-calc(11% - 0px)"}},[_c('Tree',{attrs:{"isLoading":_vm.treeDataIsLoading,"treeData":_vm.treeData},on:{"nodeClick":_vm.nodeClick,"filterNode":_vm.filterNode}})],1),_c('div',{staticClass:"leftrig-rig-box",staticStyle:{"max-width":"calc(88% - 1px)"}},[_c('div',{staticClass:"margno-t"},[_c('div',{staticClass:"table-er-box"},[(!_vm.parentId && _vm.parentId.length == 0)?_c('el-button',{on:{"click":function($event){_vm.formDialogVisiable = true;
              _vm.title = '新增指标';}}},[_vm._v("新增指标")]):_c('div',{staticClass:"prompt-title-box",staticStyle:{"color":"red"}},[_vm._v(" 星星指标最多两级 ")])],1),_c('Table',{attrs:{"isLoading":_vm.tableDataIsLoading,"tableData":_vm.tableData
              .filter(function (c) { return c.FJId == _vm.nodeId || c.FJId == c.Id; })
              .slice(
                (_vm.pagerInfo.pageIndex - 1) * _vm.pagerInfo.pageSize,
                (_vm.pagerInfo.pageIndex - 1) * _vm.pagerInfo.pageSize +
                  _vm.pagerInfo.pageSize
              ),"dimensions":_vm.dimensions},on:{"editData":_vm.editData,"delItem":_vm.delItem}}),_c('div',{staticClass:"pagin-box"},[_c('div',{staticClass:"pagin-wz"},[_c('Pager',{attrs:{"pagerInfo":_vm.dynamicPager},on:{"changeIndex":_vm.changeIndex}})],1)])],1)])]),_c('FormDialog',{attrs:{"dimensionsSelectDisable":_vm.isChild,"visible":_vm.formDialogVisiable,"dimensions":_vm.dimensions,"form":_vm.formData,"title":_vm.title},on:{"closeDialog":_vm.closeFormDialog,"confirmDialog":_vm.confirmDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }